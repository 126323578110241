import InterceptorService from "./InterceptorService";
const finalJson = {
  ...InterceptorService,
  getThousandsGroupRegex(thousandsGroupStyle) {
    switch (thousandsGroupStyle) {
      case "lakh":
        return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;

      case "wan":
        return /(\d)(?=(\d{4})+(?!\d))/g;

      case "thousand":
      default:
        return /(\d)(?=(\d{3})+(?!\d))/g;
    }
  },
  // numberFormatWithDots(str) {
  //   str = str.toString().split('.');
  //   let thousandsGroupRegex = this.getThousandsGroupRegex("thousand");
  //   let index = str[0].search(/[1-9]/);
  //   index = index === -1 ? str[0].length : index;
  //   return (
  //     str[0].substring(0, index) +
  //     str[0].substring(index, str[0].length).replace(thousandsGroupRegex, "$1.") + 
  //     (str[1] !== undefined ? ',' + str[1].substring(0, 2) : '')
  //   );
  // },

  phNumber (str) {
    if(!str) return 0;
      str=str.toString();
      let lastThree = str.substring(str.length-3);
      let otherNumbers = str.substring(0,str.length-3);
      if(otherNumbers !== '') lastThree = ',' + lastThree;
      return otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
  },
  convertInputNumberIntoPh (str) {
    let str1 = String(str).toString();
    let formatedStr = str1.replaceAll(",", "");
    if (formatedStr.indexOf('.') > -1) {
        let firstPart = formatedStr.substring(0, formatedStr.indexOf('.'));
        let lastPart = formatedStr.substring(formatedStr.indexOf('.'), formatedStr.length);
        lastPart = lastPart.length > 2 ? lastPart.substring(0, 3) : lastPart;   
        return this.phNumber(firstPart)+lastPart;
    }
    return this.phNumber(formatedStr);
  },
  numberFormatWithDots(str) {
    let phNumberResult = this.phNumber(str);
    return this.convertInputNumberIntoPh(phNumberResult);
  },
  getAuth() {
    return this.get("/dif-ph-calc/mmv/get_auth");
  },
  getInventoryFundingConfigData(body, headers = null) {
    return this.post("/dif-ph-calc/mmv/get_form_fields_car_mrp", body);
  },
  calculatePrice(body, headers = null) {
    return this.post("/dif-ph-calc/mmv/get_mmv_calculation", body)
  },
  getMMVbyYear(body) {
    return this.post("/dif-ph-calc/mmv/get_mmv_by_year", body);
  },
  getMakebyYear(body) {
    return this.post("/dif-ph-calc/mmv/get_make_by_year", body);
  },
  getModelbyYear(body) {
    return this.post("/dif-ph-calc/mmv/get_model_by_year", body);
  },
  getVariantbyYear(body) {
    return this.post("/dif-ph-calc/mmv/get_version_by_year", body);
  },
};

export default finalJson;