import React, { Component } from 'react';
import Select, { components } from 'react-select';
import Button from "../elements/Button";
import { GeneralService } from '../../services';
import Loader from '../elements/Loader';
import MRPCarQuotes from './MRPCarQuotes';
import Modal from '../elements/Modal';
import "react-datepicker/dist/react-datepicker.css";
import md5 from 'md5';


const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};


class FinancierMRP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      API_PASSWORD: "",
      password: "",
      make_year_options: [],
      financier_make_options: [],
      financier_model_options: [],
      financier_version_options: [],
      oto_make_year: 0,
      financier_make: 0,
      financier_model: 0,
      financier_version: 0,
      errors: {},
      loading: false,
      basicAuth: false,
      error: "",
      dealer_options: [],
      lastActivity: Date.now(),
      dealer: 0,
      calculator_data:null,
      required_form_fields: {
        oto_make_year: "required",
        financier_make: "required",
        financier_model: "required",
        financier_version: "required",
        dealer: "required"
      }
    };
  }

  checkAuth = (e) => {
    let password = this.state.password || '';
    password = (password && md5(password)) || '';
    if (password && password === this.state.API_PASSWORD) {
      localStorage.setItem("basicAuth", true);
      this.startInactivityTimer();
      this.setupEventListeners();
      this.setState({ basicAuth: true });
      this.getInventoryFundingConfigData();
    } else {
      localStorage.clear();
      this.setState({ error: "Wrong Password! Please enter a correct Password." });
    }
  }

  componentWillUnmount() {
    this.clearInactivityTimer();
    this.removeEventListeners();
  }

  startInactivityTimer = () => {
    this.inactivityInterval = setInterval(this.logoutAfterOneHour, 5000); // Check every 5 seconds
  };

  clearInactivityTimer = () => {
    clearInterval(this.inactivityInterval);
  };

  setupEventListeners = () => {
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
    window.addEventListener('click', this.handleUserActivity);
    window.addEventListener('scroll', this.handleUserActivity);
  };

  removeEventListeners = () => {
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    window.removeEventListener('click', this.handleUserActivity);
    window.removeEventListener('scroll', this.handleUserActivity);
  };

  logoutAfterOneHour = () => {
    const { lastActivity } = this.state;
    const now = Date.now();
    const timeSinceLastActivity = now - lastActivity;
    const oneHourInMilliseconds = 30 * 60 * 60 * 1000;

    // console.log(timeSinceLastActivity, oneHourInMilliseconds)
    if (timeSinceLastActivity >= oneHourInMilliseconds) {
      this.setState({ basicAuth: false });
      localStorage.clear();
      this.clearInactivityTimer();
      this.removeEventListeners();
    }
  };

  handleUserActivity = () => {
    this.setState({ lastActivity: Date.now() });
  };

  componentDidMount = async () => {
    // document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.setState({ basicAuth: localStorage.getItem("basicAuth") });
    this.setState({ loading: true })
    if (!localStorage.getItem("basicAuth")) {
      await this.getAuth();
    } else {
      this.startInactivityTimer();
      this.setupEventListeners();
      this.getInventoryFundingConfigData();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    let { oto_make_year,financier_make,financier_model } = this.state;
    if (+prevState.oto_make_year !== +oto_make_year) {
      this.getMakebyYear();
    }
    if (+prevState.financier_make !== +financier_make) {
      this.getModelbyYear();
    }
    if (+prevState.financier_model !== +financier_model) {
      this.getVariantbyYear();
    }
  }

  getMakebyYear = async () => {
    this.setState({ loading: true });
    let body = {
      "make_year": this.state.oto_make_year
    };
    if (body.make_year) {
      GeneralService.getMakebyYear(body)
        .then(resp => {
          if (resp.status === 200) {
            let { data } = resp.data, stateObj = {};
            stateObj = {
              financier_make_options: data?.make || []
            };
            stateObj = {
              ...stateObj,
              financier_make: 0,
              financier_model: 0,
              financier_version: 0
            }
            this.setState(stateObj);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.setState({ loading: false });
        })
    } else {
      this.setState({ loading: false });
    }
  }

  getModelbyYear = async () => {
    this.setState({ loading: true });
    let body = {
      "make_year": this.state.oto_make_year,
      "make_id":this.state.financier_make
    };
    if (body.make_year && body.make_id) {
      GeneralService.getModelbyYear(body)
        .then(resp => {
          if (resp.status === 200) {
            let { data } = resp.data, stateObj = {};
            stateObj = {
              financier_model_options: data?.model || []
            };
            stateObj = {
              ...stateObj,
              financier_model: 0,
              financier_version: 0
            }
            this.setState(stateObj);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.setState({ loading: false });
        })
    } else {
      this.setState({ loading: false });
    }
  }

  getVariantbyYear = async () => {
    this.setState({ loading: true });
    let body = {
      "make_year": this.state.oto_make_year,
      "make_id":this.state.financier_make,
      "model_id":this.state.financier_model,
    };
    if (body.make_year && body.make_id) {
      GeneralService.getVariantbyYear(body)
        .then(resp => {
          if (resp.status === 200) {
            let { data } = resp.data, stateObj = {};
            stateObj = {
              financier_version_options: data?.version || []
            };
            stateObj = {
              ...stateObj,
              financier_version: 0
            }
            this.setState(stateObj);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.setState({ loading: false });
        })
    } else {
      this.setState({ loading: false });
    }
  }

  getAuth = async () => {
    await GeneralService.getAuth()
      .then(resp => {
        if (resp.status === 200 && resp.data.status === 200) {
          let { data } = resp.data;
          if (data) {
            this.setState({ API_PASSWORD: data.password });
          }
        }
      });
  }

  getInventoryFundingConfigData = (config_type = "financier_data") => {
    this.setState({ loading: true });
    let body = {
      "source": "WEB"
    };
    GeneralService.getInventoryFundingConfigData(body)
      .then(resp => {
        if (resp.status === 200 && resp.data.status === 200) {
          let { data } = resp.data, stateObj = {};
          if (config_type === "financier_data") {
            stateObj = {
              make_year_options: data.make_year || [],
              dealer_options: (data && data.dealer_list && data.dealer_list.map(dealer => {
                return {
                  id: dealer.id,
                  value: dealer.organization + " (" + dealer.gcd_code + ")",
                  is_priority_dealer: dealer.is_priority_dealer
                }
              })) || []
            };
          } else if (config_type === "financier_mmv") {
            stateObj = {
              financier_make_options: data.financier_make || [],
              financier_model_options: data.financier_model || [],
              financier_version_options: data.financier_version || []
            };
          }
          stateObj = {
            ...stateObj
          }
          this.setState(stateObj);
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  getMMVbyYear = () => {
    this.setState({ loading: true });
    let body = {
      "financier_id": 13,
      "make_year": this.state.oto_make_year
    };
    if (body.make_year) {
      GeneralService.getMMVbyYear(body)
        .then(resp => {
          if (resp.status === 200) {
            let { data } = resp.data, stateObj = {};
            stateObj = {
              financier_make_options: data?.make || [],
              financier_model_options: data?.model || [],
              financier_version_options: data?.version || []
            };
            stateObj = {
              ...stateObj,
              financier_make: 0,
              financier_model: 0,
              financier_version: 0
            }
            this.setState(stateObj);
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.setState({ loading: false });
        })
    } else {
      this.setState({ loading: false });
    }
  }


  handleChangeOption = (field_name, field_obj, isMulti = false) => {
    let { market_mrp_city, errors, required_form_fields } = this.state;
    let stateObj = {};
    if (isMulti === true && field_obj && Array.isArray(field_obj)) {
      let city_ids = field_obj.map(v => v.city_id);
      stateObj = { [field_name]: city_ids }
      // For adding or removing rules from Market MRP form
      if (city_ids.length) {
        required_form_fields = {
          ...required_form_fields,
          market_mrp_make_year: "required",
          market_make: "required",
          market_model: "required",
          market_version: "required"
        }
      } else {
        delete required_form_fields.market_mrp_make_year;
        delete required_form_fields.market_make;
        delete required_form_fields.market_model;
        delete required_form_fields.market_version;
      }
      if (field_name === "market_mrp_city" && market_mrp_city.length === 0) stateObj["have_market_mrp_city"] = true;
      this.setState({ required_form_fields })
    } else if (field_obj && field_obj.hasOwnProperty('id')) {
      stateObj = { [field_name]: field_obj.id };
      // if (field_name === "customer_region" && customer_region === 0) stateObj["have_customer_region"] = true;
      if (field_name === "financier_make") {
        stateObj["financier_model"] = 0;
        stateObj["financier_version"] = 0;
      }
      if (field_name === "financier_model") {
        stateObj["financier_version"] = 0;
      }
      if (field_name === "market_make") {
        stateObj["market_model"] = 0;
        stateObj["market_version"] = 0;
      }
      if (field_name === "market_model") {
        stateObj["market_version"] = 0;
      }
    } else if (field_obj && field_obj.hasOwnProperty('v_id')) {
      stateObj = { [field_name]: field_obj.v_id };
    } else {
      stateObj = { [field_name]: field_obj };
    }
    delete errors[field_name];
    stateObj = {
      ...stateObj,
      errors: errors
    }
    this.setState(stateObj);
  }

  isValidForm = () => {
    let isValid = true;
    let errorsObj = {}

    Object.keys(this.state.required_form_fields).forEach(element => {
      if (!this.state[element]) {
        isValid = false;
        errorsObj = { ...errorsObj, [element]: "The " + element.split('_').join(' ') + " field is required" }
      }
    });
    this.setState({ errors: errorsObj })
    return isValid
  }

  calculatePrice = (e) => {
    e.preventDefault();
    let isValid = this.isValidForm();
    if (isValid) {
      let {  oto_make_year, financier_make, financier_model, financier_version, dealer,  financier_make_options, financier_model_options, financier_version_options } = this.state;
      let make_name = financier_make_options.find(v => v.id === financier_make)?.make;
      let model_name = financier_model_options.find(v => v.id === financier_model)?.model;
      let version_name = financier_version_options.find(v => v.id === financier_version)?.version;

      let body = {
        
          "make": make_name,
          "make_id": financier_make,
          "model": model_name,
          "model_id": financier_model,
          "variant": version_name,
          "variant_id": financier_version,
          "make_year": oto_make_year,
          "dealer_id": dealer,

        
      }

      this.setState({ loading: true })
      GeneralService.calculatePrice(body)
        .then(resp => {
          if (resp.status === 200 && resp.data.status === 200) {
            let { data } = resp.data;
            this.setState({ calculator_data: data });
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.setState({ loading: false });
        })
    }
  }

  backToFormHandler = () => {
    this.setState({ calculator_data: null });
  }

  render() {
    const {  make_year_options, oto_make_year, financier_make_options, financier_model_options, financier_version_options, financier_make, financier_model, financier_version,  errors, loading,  dealer_options, dealer,calculator_data } = this.state;
    

    if (calculator_data) {
      return (
        <MRPCarQuotes
          calculator_data={calculator_data}
          dealer_options={dealer_options}
          backToFormHandler={this.backToFormHandler}
        />
      )
    }
    return (
      <>
        {this.state.basicAuth ? (
          <div className="mrp-contaier">
            <div className="financier-outer">
              <h1 className='price-title'>Price Calculator</h1>
              <form
                className="mrp-fileds"
                onSubmit={this.calculatePrice}
                autoComplete="off"
              >
              

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={make_year_options.filter(
                        ({ id }) => id === oto_make_year
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "oto_make_year"
                      )}
                      options={make_year_options?.filter(v => v.value >= 2000)}
                      name="oto_make_year"
                      placeholder={
                        "Year of Manufacturing *"
                      }
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ id }) => id}
                      getOptionValue={({ value }) => value}
                    />
                    <span className="error-msg">
                      {errors.oto_make_year ? errors.oto_make_year.replace("oto","") : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_make"
                      value={financier_make_options.filter(
                        ({ id }) => id === financier_make
                      )}
                      onChange={this.handleChangeOption.bind(this, "financier_make")}
                      options={financier_make_options}
                      placeholder={"Make *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ make }) => make}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_make ? errors.financier_make.replace("financier","") : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_model"
                      value={financier_model_options.filter(
                        ({ id }) => id === financier_model
                      )}
                      onChange={this.handleChangeOption.bind(this, "financier_model")}
                      options={financier_model_options}
                      placeholder={"Model *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ model }) => model}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_model ? errors.financier_model.replace("financier","") : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      name="financier_version"
                      value={financier_version_options.filter(
                        ({ id }) => id === financier_version
                      )}
                      onChange={this.handleChangeOption.bind(
                        this,
                        "financier_version"
                      )}
                      options={financier_version_options}
                      placeholder={"Variant *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      getOptionLabel={({ version }) => version}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.financier_version ? errors.financier_version.replace("financier","") : ""}
                    </span>
                  </div>
                </fieldset>

                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      components={{ ValueContainer: CustomValueContainer }}
                      value={dealer_options.filter(
                        ({ id }) => id === dealer
                      )}
                      onChange={this.handleChangeOption.bind(this, "dealer")}
                      options={dealer_options}
                      name="dealer"
                      placeholder={"Dealer *"}
                      className="react-select"
                      classNamePrefix="react-select"
                      isSearchable="false"
                      getOptionLabel={({ value }) => value}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error-msg">
                      {errors.dealer ? errors.dealer : ""}
                    </span>
                  </div>
                </fieldset>


                <div className="btn-submit m-md-t">
                  <Button
                    className="btn-primary"
                    type="submit"
                    title={"Calculate Price"}
                    id="submit_approve"
                    name="submit_approve"
                  />
                </div>
              </form>
            </div>
            {loading ? <Loader /> : null}
          </div>
        ) : (
          <div className="view-login-popup">
            <Modal show={true}>
              <div className="modal-header">
                <h2>Password</h2>
              </div>
              <div className="modal-body">
                <div className="material login-field">
                  <input
                    type="password"
                    placeholder=" "
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    value={this.state.password || ""}
                    name="password"
                    className="form-input"
                  />
                  <label data-label={"Password"} className="form-label"></label>
                  <span className="error">{this.state.error}</span>
                </div>
                <button
                  className="btn-primary"
                  onClick={(e) => this.checkAuth(e)}
                >
                  Login
                </button>
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default FinancierMRP;