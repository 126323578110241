import React, { Component } from 'react';
import { GeneralService } from '../../services';

class MRPCarQuotes extends Component {
  render() {
    let { calculator_data, backToFormHandler,dealer_options } = this.props;
    let dealerData=dealer_options.filter((dl)=>+dl.id===+calculator_data.dealer_id)
    return (
      <div className='mrp-contaier'>
        <div className='financier-outer'>
          <div className='mrp-heading-txt'>
            <h2 className='mmvdetailsph-title'>
              <i className='ic-arrow_back m-md-r link-btn' onClick={backToFormHandler}></i>
              Calculation Details</h2>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <ul className='mmvdetails-vehicle'>
                <li>{calculator_data?.make_name}</li>
                <li>{calculator_data?.model_name}</li>
                <li>{calculator_data?.variant_name}</li>
                <li>{calculator_data?.make_year}</li>
              </ul>

              <p>{dealerData?.[0]?.value}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 mmvdetails-calculationdiv'>
              <h2>UCF</h2>
              <table>
                <tr>
                  <td>Minimum</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.ucf?.minimum || 0)}</td>
                </tr>

                <tr>
                  <td>Maximum</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.ucf?.maximum || 0)}</td>
                </tr>

                <tr>
                  <td>Listing</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.ucf?.listings || 0)}</td>
                </tr>
              </table>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 mmvdetails-calculationdiv'>
              <h2>Classified Loan</h2>
              <table >
                <tr>
                  <td>Minimum</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.classified?.minimum || 0)}</td>
                </tr>

                <tr>
                  <td>Maximum</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.classified?.maximum || 0)}</td>
                </tr>

                <tr>
                  <td>Listing</td>
                  <td>{GeneralService.numberFormatWithDots(calculator_data?.classified?.listings || 0)}</td>
                </tr>
              </table>
            </div>
          </div>

        </div>

        <div className='mmvdetails-finalrange'>
          
              <h2> {GeneralService.numberFormatWithDots(calculator_data?.final_range?.min || 0)} - {GeneralService.numberFormatWithDots(calculator_data?.final_range?.max || 0)}</h2>
              <span>Final Range</span>
            
        </div>

      </div>

    )
  }
}

export default MRPCarQuotes;